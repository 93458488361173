import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import axios from 'axios';
import { nanoid } from 'nanoid'
import { useAuth } from 'src/hooks/useAuthContext';

export default function AddMovieTicket({ show, closeModal, refreshPage, movieId }) {
    const { user } = useAuth()
    const token = user.access_token

    const [validationError, setValidationError] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    //  state to hold form data
    const [formData, setFormData] = useState({
        ticketId: nanoid(),
        type: '', ticketName: '', maxQuantity: '', price: '', serviceFee: '', ticketDesc: '',
        saleStart: '', saleStop: '', saleMode: 'public', saleChannel: 'everyone', minOrder: '',
        maxOrder: ''
    })

    //  method to reset form fields to empty
    const resetForm = () => {
        setFormData({
            ticketId: nanoid(),
            type: '', ticketName: '', maxQuantity: '', price: '', serviceFee: '', ticketDesc: '',
            saleStart: '', saleStop: '', saleMode: 'public', saleChannel: 'everyone', minOrder: '',
            maxOrder: ''
        })
    }

    //  call custom fetch hook to post data
    // const { apiData, res, apiError, isLoading, validationError, postData } = useFetch('POST', `${appURL}/api/events/admin/setup/age_range/create`)

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }


    //  method to submit form
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        axios
            .post(`${appURL}/api/location/admin/movies/ticket/create/${movieId}`, formData, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then((res) => {
                successNotify(res.data.message)
                resetForm()
                closeModal()
                refreshPage()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setValidationError(error.response.data.errors)
                } else if (error.response.status === 425) {
                    errorNotify(error.response.data.message)
                }
                else {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Movie Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='status' style={{ display: 'block' }}>
                                        <span>Ticket Type</span>
                                        <select
                                            className='form-control'
                                            id='type'
                                            value={formData.type}
                                            onChange={handleChange}
                                            name='type'
                                        >
                                            <option value=''>--Select Ticket Type--</option>
                                            <option value="free">Free</option>
                                            <option value="paid">Paid</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='ticketName' style={{ display: 'block' }}>
                                        <span className='text-capitalize'>Ticket Name</span>
                                        <input className='form-control'
                                            type="text" name='ticketName'
                                            // value={formData.ticketName} 
                                            onChange={handleChange}
                                            placeholder='Early Bird, Regular, VIP'
                                        />
                                    </label>
                                    {validationError.ticketName && <small className='text-danger my-1'>{validationError.ticketName[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='ticketDesc' style={{ display: 'block' }}>
                                        <span>Ticket description</span>
                                        <textarea className='form-control'
                                            rows={1}
                                            name='ticketDesc'
                                            // value={formData.ticketDesc} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.ticketDesc && <small className='text-danger my-1'>{validationError.ticketDesc[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='maxQuantity' style={{ display: 'block' }}>
                                        <span>Quantity</span>
                                        <input className='form-control'
                                            type="number" name='maxQuantity'
                                            value={formData.maxQuantity}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.maxQuantity && <small className='text-danger my-1'>{validationError.maxQuantity[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='price' style={{ display: 'block' }}>
                                        <span>Price (₦)</span>
                                        <input className='form-control'
                                            type="text" name='price'
                                            value={formData.price}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.price && <small className='text-danger my-1'>{validationError.price[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='price' style={{ display: 'block' }}>
                                        <span>Service Fee (₦)</span>
                                        <input className='form-control'
                                            type="text" name='serviceFee'
                                            value={formData.serviceFee}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.serviceFee && <small className='text-danger my-1'>{validationError.serviceFee[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='saleStart' style={{ display: 'block' }}>
                                        <span>Ticket sale starts</span>
                                        <input className='form-control'
                                            type="datetime-local" name='saleStart'
                                            // value={formData.saleStart} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.saleStart && <small className='text-danger my-1'>{validationError.saleStart[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='saleStop' style={{ display: 'block' }}>
                                        <span>Ticket sale stops</span>
                                        <input className='form-control'
                                            type="datetime-local" name='saleStop'
                                            // value={formData.saleStop} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.saleStop && <small className='text-danger my-1'>{validationError.saleStop[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='minOrder' style={{ display: 'block' }}>
                                        <span>Min. Ticket per Order</span>
                                        <input className='form-control'
                                            type="number" name='minOrder' min='1' max='10'
                                            // value={formData.minOrder} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.minOrder && <small className='text-danger my-1'>{validationError.minOrder[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='maxOrder' style={{ display: 'block' }}>
                                        <span>Max. Ticket per Order</span>
                                        <input className='form-control'
                                            type="number" name='maxOrder' min='1' max='10'
                                            // value={formData.maxOrder} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.maxOrder && <small className='text-danger my-1'>{validationError.maxOrder[0]}</small>}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}