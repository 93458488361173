import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import axios from 'axios';
import { nanoid } from 'nanoid'
import useFetch from '../../hooks/useFetch';
import { Card, Container, Typography } from '@mui/material';
import './AddEventModal.css'
import { useAuth } from '../../hooks/useAuthContext';

export default function AddEvent({ show, closeModal, refreshPage }) {
    const { user } = useAuth()
    const token = user.access_token

    //  state to hold form data
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        tags: '',
        visibility: 'public',
        eventType: 'trending',
        startDate: '',
        stopDate: '',
        locationName: '',
        country: '',
        state: '',
        city: '',
        ageRange: '',
        bannerImg: '',
        mobileImg: '',
        coverImg: '',
    })

    //    countries state
    const [countries, setCountries] = useState([])

    //    state to hold event age ranges
    const [ageRange, setEventAgeRange] = useState([])

    //    state to hold event category
    const [category, setEventCategory] = useState([])

    //    banner image preview
    const [bannerImgUrl, setBannerImgUrl] = useState('')

    //    mobile image preview
    //   const [mobileImgUrl, setMobileImgUrl] = useState('')

    //    cover image preview
    const [coverImgUrl, setCoverImgUrl] = useState('')

    //    image error
    const [imageError, setImageError] = useState('')

    const [validationError, setValidationError] = useState({})
    const [isLoading, setIsLoading] = useState(false);


    //   console.log(bannerImgError, mobileImgError, coverImgError);

    //  method to reset form fields to empty
    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            category: '',
            tags: '',
            visibility: 'public',
            eventType: 'trending',
            startDate: '',
            stopDate: '',
            locationName: '',
            country: '',
            state: '',
            city: '',
            ageRange: '',
            bannerImg: '',
            mobileImg: '',
            coverImg: '',
            ticket: []
        })
        setBannerImgUrl('')
        setImageError('')
        // setMobileImgUrl('')
        setCoverImgUrl('')
    }

    const [ticket, setTicket] = useState([])

    const [apiCreds, setApiCreds] = useState({
        url: `${appURL}/api/events/admin`,
        method: 'GET'
    })

    //  call custom fetch hook to post data
    const { res, apiError, postData } = useFetch(apiCreds.method, apiCreds.url)

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    //  listen for changes on ticket settings form inputs and store in state
    const handleTicketChange = (index, e) => {
        const { name, value } = e.target

        setTicket(prevTicket => prevTicket.map((t, i) => (
            i === index ? { ...t, [name]: value } : t
        )))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }

    //    method for image select
    const changeHandler = (event) => {
        const { name, files } = event.target
        setFormData(prevFormData => (
            { ...prevFormData, [name]: files[0] }
        ))

        //  preview image
        switch (name) {
            case 'bannerImg':
                setBannerImgUrl(URL.createObjectURL(files[0]))
                return
            // case 'mobileImg' :
            //     setMobileImgUrl(URL.createObjectURL(files[0]))
            //     return
            case 'coverImg':
                setCoverImgUrl(URL.createObjectURL(files[0]))
                return
            default:
                break
        }

    };

    //  method to submit form
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        let form = new FormData()

        form.append('name', formData.name)
        form.append('description', formData.description)
        form.append('category', formData.category)
        form.append('tags', formData.tags)
        form.append('visibility', formData.visibility)
        form.append('eventType', formData.eventType)
        form.append('startDate', formData.startDate)
        form.append('stopDate', formData.stopDate)
        form.append('locationName', formData.locationName)
        form.append('country', formData.country)
        form.append('state', formData.state)
        form.append('city', formData.city)
        form.append('ageRange', formData.ageRange)
        form.append('bannerImg', formData.bannerImg)
        form.append('mobileImg', formData.mobileImg)
        form.append('coverImg', formData.coverImg)
        form.append('ticket', JSON.stringify(ticket))

        // setApiCreds({method: 'POST', url: `${appURL}/api/events/admin/create`})
        // postData(form)
        // setApiCreds({ 
        //     url: `${appURL}/api/events/admin`,
        //     method: 'GET'
        // })


        axios
            .post(`${appURL}/api/events/admin/create`, form, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then((res) => {
                successNotify(res.data.message)
                resetForm()
                closeModal()
                refreshPage()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setValidationError(error.response.data.errors)
                } else if (error.response.status === 425) {
                    errorNotify(error.response.data.message)
                } else if (error.response.status === 420) {
                    errorNotify('Error with image upload')
                    setImageError(error.response.data.errors)
                }
                else {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    //    function to fetch countries
    const fetchCountries = () => {
        axios
            .get("https://restcountries.com/v3.1/region/africa")
            .then((res) => {
                setCountries(res.data)
            })
            .finally(() => {
            });
    }

    //    function to fetch event age range
    const fetchAgeRange = () => {
        axios
            .get(`${appURL}/api/events/admin/setup/age_range/list/active`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((res) => {
                setEventAgeRange(res.data.data)
            })
            .finally(() => {
            });
    }

    //    function to fetch event age range
    const fetchEventCategory = () => {
        axios
            .get(`${appURL}/api/events/admin/setup/category/list/active`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((res) => {
                setEventCategory(res.data.data)
            })
            .finally(() => {
            });
    }

    //    function to add tickets
    const addTickets = (type) => {
        const ticketObject = {
            ticketId: nanoid(), type, ticketName: '', maxQuantity: '', price: '', serviceFee: '', ticketDesc: '',
            saleStart: '', saleStop: '', saleMode: 'public', saleChannel: 'everyone', minOrder: '',
            maxOrder: ''
        }

        setTicket(prevTicket => (
            [...prevTicket, ticketObject]
        ))
    }

    //    function to delete ticket
    const handleRemoveTicket = (i) => {
        setTicket(prevTicket => (
            prevTicket.filter((t, idx) => i != idx))
        )
    }

    //  show  success or error notification on data submission
    useEffect(() => {
        if (res) {
            successNotify(res)
            resetForm()
            closeModal()
            refreshPage()
        }
        if (apiError) {
            errorNotify(apiError)
        }
    }, [res, apiError])

    //    invoke fetch countries function
    useEffect(() => {
        fetchCountries()
        fetchAgeRange()
        fetchEventCategory()
    }, [])

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl" backdrop="static" keyboard={false}>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-light'>
                        <Card className='mb-5'>
                            <Container className='py-md-5 px-md-5'>
                                <Typography variant="h4" className='font-weight-normal pb-4'>
                                    Basic Information
                                </Typography>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Name of the Event</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                        size='lg'
                                        required
                                    />
                                    {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formDescription">
                                    <Form.Label>Event description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        name='description'
                                        value={formData.description}
                                        onChange={handleChange}
                                        required
                                    />
                                    {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
                                </Form.Group>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='category' style={{ display: 'block' }}>
                                                <span>Event Category</span>
                                                <select
                                                    className='form-control form-control-lg mt-2'
                                                    id='category'
                                                    value={formData.category}
                                                    onChange={handleChange}
                                                    name='category'
                                                >
                                                    <option value=''>--Select Category--</option>
                                                    {category && (
                                                        category.map(cat => (
                                                            <option key={cat.id} value={cat.id}>{cat.name}</option>
                                                        ))
                                                    )}
                                                </select>
                                            </label>
                                            {validationError.category && <small className='text-danger my-1'>{validationError.category[0]}</small>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='tags' style={{ display: 'block' }}>
                                                <span>Add tags (separated by commas)</span>
                                                <input className='form-control mt-2 form-control-lg' type="text" name='tags' value={formData.tags} onChange={handleChange} />
                                            </label>
                                            {validationError.tags && <small className='text-danger my-1'>{validationError.tags[0]}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='visibility' style={{ display: 'block' }}>
                                                <span>Event Visibility</span>
                                                <select
                                                    className='form-control form-control-lg mt-2'
                                                    id='visibility'
                                                    value={formData.visibility}
                                                    onChange={handleChange}
                                                    name='visibility'
                                                >
                                                    <option value="public">public</option>
                                                    <option value="private">Private</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='visibility' style={{ display: 'block' }}>
                                                <span>Event Type</span>
                                                <select
                                                    className='form-control form-control-lg mt-2'
                                                    id='visibility'
                                                    value={formData.eventType}
                                                    onChange={handleChange}
                                                    name='eventType'
                                                >
                                                    <option value="trending">Trending</option>
                                                    <option value="featured">Featured</option>
                                                    <option value="beach">Beach Event</option>
                                                    <option value="club">Club Reservation</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Card>
                        <Card className='mb-5'>
                            <Container className='py-md-5 px-md-5'>
                                <Typography variant="h4" className='font-weight-normal pb-4'>
                                    When and Where
                                </Typography>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='startDate' style={{ display: 'block' }}>
                                                <span>Event Starts</span>
                                                <input className='form-control mt-2 form-control-lg' type="datetime-local"
                                                    name='startDate' id='startDate'
                                                    value={formData.startDate} onChange={handleChange}
                                                    required
                                                />
                                            </label>
                                            {validationError.startDate && <small className='text-danger my-1'>{validationError.startDate[0]}</small>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='stopDate' style={{ display: 'block' }}>
                                                <span>Event Stops</span>
                                                <input className='form-control mt-2 form-control-lg' type="datetime-local" id='stopDate'
                                                    name='stopDate' value={formData.stopDate} onChange={handleChange} required
                                                />
                                            </label>
                                            {validationError.stopDate && <small className='text-danger my-1'>{validationError.stopDate[0]}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='locationName' style={{ display: 'block' }}>
                                                <span>Event Location Details</span>
                                                <input className='form-control mt-2 form-control-lg'
                                                    type="text" name='locationName'
                                                    value={formData.locationName}
                                                    onChange={handleChange}
                                                    placeholder='Location Name'
                                                    required
                                                />
                                            </label>
                                            {validationError.locationName && <small className='text-danger my-1'>{validationError.locationName[0]}</small>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label htmlFor='ageRange' style={{ display: 'block' }}>
                                                <span>Age Restriction</span>
                                                <select
                                                    className='form-control form-control-lg mt-2'
                                                    id='ageRange'
                                                    value={formData.ageRange}
                                                    onChange={handleChange}
                                                    name='ageRange'
                                                    required
                                                >
                                                    <option value=''>--Select Age Range--</option>
                                                    {ageRange && (
                                                        ageRange.map(range => (
                                                            <option key={range.id} value={range.id}>{range.name}</option>
                                                        ))
                                                    )}
                                                </select>
                                            </label>
                                            {validationError.ageRange && <small className='text-danger my-1'>{validationError.ageRange[0]}</small>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='mb-3'>
                                            <label htmlFor='country' style={{ display: 'block' }}>
                                                <span></span>
                                                <select
                                                    className='form-control form-control-lg mt-2'
                                                    id='country'
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                    name='country'
                                                    required
                                                >
                                                    <option value=''>--Select Country--</option>
                                                    {countries && (
                                                        countries.map(country => (
                                                            <option key={country.name.common} value={country.name.common}>{country.name.common}</option>
                                                        ))
                                                    )}
                                                </select>
                                            </label>
                                            {validationError.country && <small className='text-danger my-1'>{validationError.country[0]}</small>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='mb-3'>
                                            <label htmlFor='state' style={{ display: 'block' }}>
                                                <span></span>
                                                <input className='form-control mt-2 form-control-lg'
                                                    type="text" name='state'
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                    placeholder='State'
                                                    required
                                                />
                                            </label>
                                            {validationError.state && <small className='text-danger my-1'>{validationError.state[0]}</small>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='mb-3'>
                                            <label htmlFor='city' style={{ display: 'block' }}>
                                                <span></span>
                                                <input className='form-control mt-2 form-control-lg'
                                                    type="text" name='city'
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    placeholder='City'
                                                    required
                                                />
                                            </label>
                                            {validationError.city && <small className='text-danger my-1'>{validationError.city[0]}</small>}
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Card>
                        <Card className='mb-5'>
                            <Container className='py-md-5 px-md-5'>
                                <Typography variant="h4" className='font-weight-normal pb-4'>
                                    Event Image
                                </Typography>
                                <div className='row'>
                                    <div className='col-md-6  mb-3'>
                                        <div className='event-image-wrapper text-center'>
                                            <Typography variant="h6" className='font-weight-normal'>
                                                Add Event Banner Image
                                            </Typography>
                                            <div className='input-wrapper'>
                                                <input
                                                    type='file'
                                                    className='form-control'
                                                    accept=".jpg, .jpeg, .png"
                                                    name='bannerImg'
                                                    onChange={changeHandler}
                                                    required
                                                />
                                            </div>
                                            <div className='image-display-wrapper d-flex mt-4' style={{ justifyContent: 'center' }}>
                                                {bannerImgUrl && (<img src={bannerImgUrl} alt='banner'
                                                    className='img-fluid' width={500}
                                                />)}
                                            </div>
                                            {imageError.banner && <small className='text-danger my-1'>{imageError.banner[0]}</small>}
                                            <Typography>
                                                (Please avoid including text on image)
                                            </Typography>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-6  mb-3'>
                            <div className='event-image-wrapper text-center'>
                                <Typography variant="h6" className='font-weight-normal'>
                                    Add Mobile Image
                                </Typography>
                                <div className='input-wrapper'>
                                    <input 
                                        type='file' 
                                        className='form-control' 
                                        accept=".jpg, .jpeg, .png" 
                                        name='mobileImg'
                                        onChange={changeHandler}
                                        required/>
                                </div>
                                <div className='image-display-wrapper'>
                                    {mobileImgUrl && (<img src={mobileImgUrl} alt='mobile' 
                                        className='img-fluid' width={80}
                                    />)}
                                </div>
                                <Typography>
                                    (584px X 960px not larger than 3mb, Please avoid including text on image)
                                </Typography>
                            </div>
                        </div> */}
                                    <div className='col-md-6 mb-3'>
                                        <div className='event-image-wrapper text-center'>
                                            <Typography variant="h6" className='font-weight-normal'>
                                                Add Event Cover Image
                                            </Typography>
                                            <div className='input-wrapper'>
                                                <input
                                                    type='file'
                                                    className='form-control'
                                                    accept=".jpg, .jpeg, .png"
                                                    name='coverImg'
                                                    onChange={changeHandler}
                                                    required
                                                />
                                            </div>
                                            <div className='image-display-wrapper d-flex mt-3' style={{ justifyContent: 'center' }}>
                                                {coverImgUrl && (<img src={coverImgUrl} alt='cover'
                                                    className='img-fluid' width={150}
                                                />)}
                                            </div>
                                            {imageError.cover && <small className='text-danger my-1'>{imageError.cover[0]}</small>}
                                            <Typography>
                                                (Please avoid including text on image)
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Card>
                        <Card className='mb-5'>
                            <Container className='py-md-5 px-md-5'>
                                <Typography variant="h4" className='font-weight-normal pb-4'>
                                    Event Ticket Settings
                                </Typography>
                                <div className='text-center mb-5'>
                                    <Typography variant='h6' className='font-weight-normal pb-3'>
                                        Ticket Type
                                    </Typography>
                                    <button onClick={() => addTickets('paid')} type='button' className='btn btn-outline-info px-md-3 mr-md-4 mr-2'>
                                        <i className='mdi mdi-plus mr-1'></i>
                                        Paid Ticket
                                    </button>
                                    <button onClick={() => addTickets('free')} type='button' className='btn btn-outline-info px-3'>
                                        <i className='mdi mdi-plus mr-1'></i>
                                        Free Ticket
                                    </button>
                                </div>
                                {ticket && ticket.map((t, i) => (
                                    <div key={i}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className='mb-3'>
                                                    <label htmlFor='ticketName' style={{ display: 'block' }}>
                                                        <span className='text-capitalize'>{t.type} Ticket</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="text" name='ticketName'
                                                            // value={ticket[i].ticketName} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                            placeholder='Early Bird, Regular, VIP'
                                                        />
                                                    </label>
                                                    {validationError.ticketName && <small className='text-danger my-1'>{validationError.ticketName[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <div className='mb-3'>
                                                    <label htmlFor='maxQuantity' style={{ display: 'block' }}>
                                                        <span>Maximum Quantity</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="number" name='maxQuantity'
                                                            // value={ticket[i].maxQuantity} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.maxQuantity && <small className='text-danger my-1'>{validationError.maxQuantity[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <div className='mb-3'>
                                                    <label htmlFor='price' style={{ display: 'block' }}>
                                                        <span>Price (₦)</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="text" name='price'
                                                            // value={ticket[i].price} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.price && <small className='text-danger my-1'>{validationError.price[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <div className='mb-3'>
                                                    <label htmlFor='price' style={{ display: 'block' }}>
                                                        <span>Service Fee (₦)</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="text" name='serviceFee'
                                                            // value={ticket[i].price} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.serviceFee && <small className='text-danger my-1'>{validationError.serviceFee[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <Typography className='font-weight-normal pb-3'>
                                                    Action
                                                </Typography>
                                                <span data-toggle="collapse" data-target={`#ticketSettings${i}`} style={{ fontSize: '22px', cursor: 'pointer', color: '#888888' }} title='More Settings'>
                                                    <i className='mdi mdi-cog-outline mr-2'></i>
                                                </span>
                                                <span onClick={() => handleRemoveTicket(i)} style={{ fontSize: '22px', cursor: 'pointer', color: '#888888' }} title='Remove'>
                                                    <i className='mdi mdi-trash-can-outline'></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row table-secondary py-4 px-3 mb-3 collapse' id={`ticketSettings${i}`}>
                                            <div className='col-md-12'>
                                                <div className='mb-3'>
                                                    <label htmlFor='ticketDesc' style={{ display: 'block' }}>
                                                        <span>Ticket description</span>
                                                        <textarea className='form-control mt-2 form-control-lg'
                                                            rows={2}
                                                            name='ticketDesc'
                                                            // value={ticket[i].ticketDesc} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.ticketDesc && <small className='text-danger my-1'>{validationError.ticketDesc[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='saleStart' style={{ display: 'block' }}>
                                                        <span>Ticket sale starts</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="datetime-local" name='saleStart'
                                                            // value={ticket[i].saleStart} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.saleStart && <small className='text-danger my-1'>{validationError.saleStart[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='saleStop' style={{ display: 'block' }}>
                                                        <span>Ticket sale stops</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="datetime-local" name='saleStop'
                                                            // value={ticket[i].saleStop} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.saleStop && <small className='text-danger my-1'>{validationError.saleStop[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='saleMode' style={{ display: 'block' }}>
                                                        <span>Ticket status</span>
                                                        <select
                                                            className='form-control form-control-lg mt-2'
                                                            id='saleMode'
                                                            // value={ticket[i].saleMode}
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                            name='saleMode'
                                                        >
                                                            <option value="public">public</option>
                                                            <option value="private">Private</option>
                                                        </select>
                                                    </label>
                                                    {validationError.saleMode && <small className='text-danger my-1'>{validationError.saleMode[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='saleChannel' style={{ display: 'block' }}>
                                                        <span>Sales channel</span>
                                                        <select
                                                            className='form-control form-control-lg mt-2'
                                                            id='saleChannel'
                                                            // value={ticket[i].saleChannel}
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                            name='saleChannel'
                                                        >
                                                            <option value="everyone">Everyone</option>
                                                            <option value="online">Online</option>
                                                            <option value="outlets">Outlets</option>
                                                        </select>
                                                    </label>
                                                    {validationError.saleChannel && <small className='text-danger my-1'>{validationError.saleChannel[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='minOrder' style={{ display: 'block' }}>
                                                        <span>Min. Ticket per Order</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="number" name='minOrder' min='1' max='10'
                                                            // value={ticket[i].minOrder} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.minOrder && <small className='text-danger my-1'>{validationError.minOrder[0]}</small>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='mb-3'>
                                                    <label htmlFor='maxOrder' style={{ display: 'block' }}>
                                                        <span>Max. Ticket per Order</span>
                                                        <input className='form-control mt-2 form-control-lg'
                                                            type="number" name='maxOrder' min='1' max='10'
                                                            // value={ticket[i].maxOrder} 
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                        />
                                                    </label>
                                                    {validationError.maxOrder && <small className='text-danger my-1'>{validationError.maxOrder[0]}</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' size='lg' className='px-5'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}