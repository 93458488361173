import { useState } from 'react';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, 
  // Checkbox 
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useLogin } from '../../../hooks/useLogin';

// ----------------------------------------------------------------------

export default function LoginForm() {

  const [formData, setFormData] = useState({
    email: '', password: ''
  })
  const [showPassword, setShowPassword] = useState(false);

  const { isLoading, apiError, validationError, login } = useLogin()

  const handleChange = (e) => {
    const {name, value} = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name] : value}
    ))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    login(formData)
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {apiError && 
          <div className='alert alert-danger'>
            {  apiError }
          </div>
        }
        <TextField 
          name="email" 
          label="Email address" 
          value={formData.email}
          type='email'
          onChange={handleChange} 
          required 
        />
        {validationError.email && <small className='text-danger my-1'>{validationError.email[0]}</small>}

        <TextField
          name="password"
          label="Password"
          value={formData.password}
          type={showPassword ? 'text' : 'password'}
          onChange={handleChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {validationError.password && <small className='text-danger my-1'>{validationError.password[0]}</small>}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>
      

      {!isLoading  && <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>}

      {isLoading  && <LoadingButton fullWidth size="large" type="button" variant="contained">
        <i className='fa fa-spinner fa-spin mr-2 fa-2x' /> Authenticating...
      </LoadingButton>}
    </form>
  );
}
