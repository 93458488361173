import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "./useAuthContext";

const useFetch = (method, url, reloadKey) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [res, setRes] = useState(null);
  const [apiError, setApiError] = useState('');
  const [formData, setFormData] = useState(null)
  const [validationError, setValidationError] = useState({})

  const { user }  = useAuth()
  const token = user.access_token

  //  function to set post data body
  const postData = (body) => {
      setFormData(body)
  }

  useEffect(() => {
    const controller = new AbortController()


    const fetchData = async (formdata) => {
      setIsLoading(true);
      setApiError(null)
      setValidationError({})
      setRes(null)

      try {
        const response = await axios({
          method, url,
          headers: {
            "Accept": "application/json",
            "Content-Type": 'multipart/form-data',
            "Authorization" : `Bearer ${token}`
          },
          data: formdata,
          signal: controller.signal
        });
        const data = await response?.data.data;
        const res = await response?.data.message

        setApiData(data);
        setRes(res)
        setIsLoading(false);
        setValidationError({})
        setApiError(null)
      } catch (error) {
        if(error.response.status===422){
          setValidationError(error.response.data.errors)
        } else if(error.response.status ===  425) {
          setApiError(error.response.data.message)
        }
        else{
            console.log(error.response.data.message)
            setApiError('An error occured. Please, try again or reload page.')
        }
        setIsLoading(false);
      }
    };

    if(method === 'GET') {
        fetchData();
    }

    if((method === 'POST' || method === 'PUT' || method === 'DELETE') && formData) {
        fetchData(formData)
    }

    return () => {
        controller.abort()
    }
  }, [url, method, formData, token, reloadKey]);

  return { isLoading, apiData, apiError, validationError, res, postData };
};

export default useFetch;