import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import { errorNotify, successNotify } from './toatification';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import useFetch from '../../hooks/useFetch';
import axios from 'axios';

export default function EditMovieTicket({ showEdit, closeModal, currentMovieTicket, refreshPage }) {

    //  state to hold form data
    const [formData, setFormData] = useState({
        type: '', ticketName: '', maxQuantity: '', price: '', serviceFee: '', ticketDesc: '',
        saleStart: '', saleStop: '', saleMode: 'public', saleChannel: 'everyone', minOrder: '',
        maxOrder: '', id: '', status: '', category: ''
    })

    const [countries, setCountries] = useState([])

    //  call custom fetch hook to post data
    const { apiData, res, apiError, isLoading, validationError, postData } = useFetch('POST', `${appURL}/api/location/admin/movies/ticket/edit`)

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        closeModal()
    }

    //  method to submit form
    const handleSubmit = async (e) => {
        e.preventDefault()
        await postData(formData)
    }

    //    set form data on mount of edit modal
    useEffect(() => {
        setFormData({
            type: currentMovieTicket ? currentMovieTicket.type : '',
            ticketName: currentMovieTicket ? currentMovieTicket.name : '',
            maxQuantity: currentMovieTicket ? currentMovieTicket.max_quantity : '',
            price: currentMovieTicket ? currentMovieTicket.price : '',
            serviceFee: currentMovieTicket ? currentMovieTicket.service_fee : '',
            ticketDesc: currentMovieTicket ? currentMovieTicket.description : '',
            saleStart: currentMovieTicket ? currentMovieTicket.sales_start : '',
            saleStop: currentMovieTicket ? currentMovieTicket.sales_stop : '',
            minOrder: currentMovieTicket ? currentMovieTicket.min_order : '',
            maxOrder: currentMovieTicket ? currentMovieTicket.max_order : '',
            status: currentMovieTicket ? currentMovieTicket.status : '',
            id: currentMovieTicket ? currentMovieTicket.id : ''
        })
    }, [currentMovieTicket])

    useEffect(() => {
        if (res) {
            successNotify(res)
            closeModal()
            refreshPage()
        }
        if (apiError) {
            errorNotify(apiError)
        }
    }, [res, apiError])


    return (
        <>
            <Modal show={showEdit} onHide={handleClose} size='lg'>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Updating..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Edit <b className='text-info'>{currentMovieTicket ? currentMovieTicket.name : 'Ticket'}</b> Ticket details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='status' style={{ display: 'block' }}>
                                        <span>Ticket Type</span>
                                        <select
                                            className='form-control'
                                            id='type'
                                            value={formData.type}
                                            onChange={handleChange}
                                            name='type'
                                        >
                                            <option value=''>--Select Ticket Type--</option>
                                            <option value="free">Free</option>
                                            <option value="paid">Paid</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='ticketName' style={{ display: 'block' }}>
                                        <span className='text-capitalize'>Ticket Name</span>
                                        <input className='form-control'
                                            type="text" name='ticketName'
                                            value={formData.ticketName} 
                                            onChange={handleChange}
                                            placeholder='Early Bird, Regular, VIP'
                                        />
                                    </label>
                                    {validationError.ticketName && <small className='text-danger my-1'>{validationError.ticketName[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='ticketDesc' style={{ display: 'block' }}>
                                        <span>Ticket description</span>
                                        <textarea className='form-control'
                                            rows={1}
                                            name='ticketDesc'
                                            value={formData.ticketDesc} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.ticketDesc && <small className='text-danger my-1'>{validationError.ticketDesc[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='maxQuantity' style={{ display: 'block' }}>
                                        <span>Quantity</span>
                                        <input className='form-control'
                                            type="number" name='maxQuantity'
                                            value={formData.maxQuantity}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.maxQuantity && <small className='text-danger my-1'>{validationError.maxQuantity[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='price' style={{ display: 'block' }}>
                                        <span>Price (₦)</span>
                                        <input className='form-control'
                                            type="text" name='price'
                                            value={formData.price}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.price && <small className='text-danger my-1'>{validationError.price[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='price' style={{ display: 'block' }}>
                                        <span>Service Fee (₦)</span>
                                        <input className='form-control'
                                            type="text" name='serviceFee'
                                            value={formData.serviceFee}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.serviceFee && <small className='text-danger my-1'>{validationError.serviceFee[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='saleStart' style={{ display: 'block' }}>
                                        <span>Ticket sale starts</span>
                                        <input className='form-control'
                                            type="datetime-local" name='saleStart'
                                            value={formData.saleStart} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.saleStart && <small className='text-danger my-1'>{validationError.saleStart[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='saleStop' style={{ display: 'block' }}>
                                        <span>Ticket sale stops</span>
                                        <input className='form-control'
                                            type="datetime-local" name='saleStop'
                                            value={formData.saleStop} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.saleStop && <small className='text-danger my-1'>{validationError.saleStop[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='minOrder' style={{ display: 'block' }}>
                                        <span>Min. Ticket per Order</span>
                                        <input className='form-control'
                                            type="number" name='minOrder' min='1' max='10'
                                            value={formData.minOrder} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.minOrder && <small className='text-danger my-1'>{validationError.minOrder[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='maxOrder' style={{ display: 'block' }}>
                                        <span>Max. Ticket per Order</span>
                                        <input className='form-control'
                                            type="number" name='maxOrder' min='1' max='10'
                                            value={formData.maxOrder} 
                                            onChange={handleChange}
                                        />
                                    </label>
                                    {validationError.maxOrder && <small className='text-danger my-1'>{validationError.maxOrder[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='mb-3'>
                                    <label htmlFor='status' style={{ display: 'block' }}>
                                        <span>Ticket Status</span>
                                        <select
                                            className='form-control'
                                            id='status'
                                            value={formData.status}
                                            onChange={handleChange}
                                            name='status'
                                        >
                                            <option value=''>--Select Ticket Status--</option>
                                            <option value="available">Available</option>
                                            <option value="sold_out">Sold Out</option>
                                            <option value="expired">Expired</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}