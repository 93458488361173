import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { appURL } from "../env"
import { useAuth } from "./useAuthContext"

export const useLogin = () => {
    const [isLoading, setIsLoading] = useState(false)
    // const [apiData, setApiData] = useState([])
    const [apiError, setApiError] = useState(null)
    const [validationError, setValidationError] = useState({})
    const navigate = useNavigate()

    const { dispatch }  = useAuth()

    const login = async (formData) => {
        setApiError(null)
        setValidationError({})
        setIsLoading(true)

        await axios.post(`${appURL}/api/login`, formData)
            .then(({data})=>{
                localStorage.setItem('userData', JSON.stringify(data))
                dispatch({ type: 'LOGIN', payload: data })
                navigate("/")
            }).catch(({response})=>{
                if(response.status===422){
                    setValidationError(response.data.errors)
                }else{
                    setApiError(response.data.message)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return  { login, isLoading, apiError, validationError }
}