import { Helmet } from 'react-helmet-async';
// import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
// import Iconify from '../components/iconify';

import './DashboardAppPage.css'
// sections
import {
  // AppTasks,
  // AppNewsUpdate,
  // AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  // AppTrafficBySite,
  // AppWidgetSummary,
  // AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { appURL } from 'src/env';
import { useAuth } from 'src/hooks/useAuthContext';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { user } = useAuth()
  const token = user.access_token

  const [eventAnalytics, setEventAnalytics] = useState(null)
  const [eventPerCategory, setEventPerCategory] = useState(null)
  const [categoryViews, setCategoryViews] = useState(null)
  const [categoryIncome, setCategoryIncome] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  //    function to fetch event analytics
  const fetchEventAnalytics = async() => {
    setIsLoading(true)
    await axios
        .get(`${appURL}/api/events/admin/analytics`,{
            headers: {
                "Accept": "application/json",
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          setEventAnalytics(res.data.data)
        })
        .finally(() => {
            setIsLoading(false)
        });
  }

  //    function to fetch event per category
  const fetchEventPerCategory = async() => {
    setIsLoading(true)
    await axios
        .get(`${appURL}/api/events/admin/percategories`,{
            headers: {
                "Accept": "application/json",
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          setEventPerCategory(res.data.data)
        })
        .finally(() => {
            setIsLoading(false)
        });
  }

  //    function to fetch event category views
  const fetchEventCategoryViews = async() => {
    setIsLoading(true)
    await axios
        .get(`${appURL}/api/events/admin/categories/views`,{
            headers: {
                "Accept": "application/json",
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          setCategoryViews(res.data.data)
        })
        .finally(() => {
            setIsLoading(false)
        });
  }

  //    function to fetch event categories income
  const fetchEventCategoryIncome = async() => {
    setIsLoading(true)
    await axios
        .get(`${appURL}/api/events/admin/categories/income`,{
            headers: {
                "Accept": "application/json",
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          setCategoryIncome(res.data.data)
        })
        .finally(() => {
            setIsLoading(false)
        });
  }

  useEffect(() => {
    fetchEventAnalytics()
    fetchEventPerCategory()
    fetchEventCategoryViews()
    fetchEventCategoryIncome()
  }, [])

  return (
    <>
      <Helmet>
        <title> Dashboard | WAGWAN </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-success'>₦{eventAnalytics ? Number(eventAnalytics.total_sales).toLocaleString() : 0}</h3>
                                <p>Total Ticket Sales</p>
                            </div>
                            <span className="b-avatar badge-light-success rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" 
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                        stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign">
                                        <line x1="12" y1="1" x2="12" y2="23"></line>
                                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                    </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-primary'>{eventAnalytics ? eventAnalytics.tickets_sold : 0}</h3>
                                <p>Tickets Sold</p>
                            </div>
                            <span className="b-avatar badge-light-primary rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" 
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
                                        className="feather feather-trending-up">
                                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                        <polyline points="17 6 23 6 23 12"></polyline>
                                    </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-danger'>{eventAnalytics ? eventAnalytics.total_transactions : 0}</h3>
                                <p>Transactions</p>
                            </div>
                            <span className="b-avatar badge-light-danger rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" 
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                        stroke-linecap="round" stroke-linejoin="round" 
                                        className="feather feather-box">
                                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                    </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-warning'>{eventAnalytics ? eventAnalytics.views : 0}</h3>
                                <p>Event Views</p>
                            </div>
                            <span className="b-avatar badge-light-warning rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" 
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                        stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z">
                                                </path><circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-primary'>{eventAnalytics ? eventAnalytics.users : 0}</h3>
                                <p>Users</p>
                            </div>
                            <span className="b-avatar badge-light-primary rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" 
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                                    stroke-linejoin="round" class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                  </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-secondary'>{eventAnalytics ? eventAnalytics.events : 0}</h3>
                                <p>Events</p>
                            </div>
                            <span className="b-avatar badge-light-secondary rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" 
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                                    stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" 
                                    width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line>
                                    <line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line>
                                  </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-info'>{eventAnalytics ? eventAnalytics.categories : 0}</h3>
                                <p>Event Categories</p>
                            </div>
                            <span className="b-avatar badge-light-info rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" 
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                                    stroke-linejoin="round" class="feather feather-pocket">
                                      <path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z">
                                        </path><polyline points="8 10 12 14 16 10"></polyline>
                                  </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className='card py-3 shadow-lg border-0 rounded-lg'>
                <div className='card-body'>
                    <div className="small-box">
                        <div className="d-flex inner">
                            <div>
                                <h3 className='text-success'>{eventAnalytics ? eventAnalytics.tickets : 0}</h3>
                                <p>Event Tickets</p>
                            </div>
                            <span className="b-avatar badge-light-success rounded-circle ml-auto" style={{width: '58px', height: '58px'}}>
                                <span className="b-avatar-custom">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" 
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                                    stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" 
                                    width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10">
                                      </line>
                                  </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {eventPerCategory && (<AppConversionRates
              title="Events Per Categories"
              subheader="Number of Events against Categories"
              chartData={eventPerCategory}
            />)}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {categoryViews && (<AppCurrentVisits
              title="Event Categories / views percentage"
              chartData={categoryViews}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.warning.main,
                theme.palette.error.main,
                theme.palette.info.main,
                theme.palette.success.main,
              ]}
            />)}
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {categoryIncome && (<AppWebsiteVisits
              title="Income per Category"
              subheader="Ticket sales per Event Category"
              chartLabels={categoryIncome.categories}
              chartData={[
                {
                  name: 'Total Income',
                  type: 'column',
                  fill: 'solid',
                  data: categoryIncome.amount,
                },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                // {
                //   name: 'Team C',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />)}
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
