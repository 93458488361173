import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuthContext';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { appURL } from 'src/env';
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function EventAnalyticsPage() {
  const { id } = useParams();
    const { user } = useAuth()
    const token = user.access_token

    const [eventAnalytics, setEventAnalytics] = useState(null)
    const [eventAttendees, setEventAttendees] = useState([])
    const [eventTickets, setEventTickets] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({
        search:  ''
    })

    const handleChange = (e) => {
        const {name, value} = e.target
        setFilters(prevFilter => (
            {...prevFilter, [name]: value}
        ))
    }
    //    function to fetch user event
    const fetchEventAnalytics = async() => {
        setIsLoading(true)
        await axios
            .get(`${appURL}/api/events/my_events/analytics/${id}`,{
                headers: {
                    "Accept": "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
              setEventAnalytics(res.data.data)
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    //    function to fetch user event attendees
    const fetchEventAttendees = async() => {
        setIsLoading(true)
        await axios
            .post(`${appURL}/api/events/my_events/attendees/${id}`,filters, {
                headers: {
                    "Accept": "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
              setEventAttendees(res.data.data)
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    //    function to fetch user event tickets
    const fetchEventTickets = async() => {
        setIsLoading(true)
        await axios
            .get(`${appURL}/api/events/my_events/tickets/${id}`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
              setEventTickets(res.data.data)
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        fetchEventAnalytics()
        fetchEventTickets()
    }, [])

    useEffect(() => {
        fetchEventAttendees()
    }, [filters])

  return (
    <>
      <Helmet>
        <title> Manage Event | Wagwan </title>
      </Helmet>

      <div className='user-events'>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Event Analytics
          </Typography>
        </Stack>
        <div className='row'>
          <div className='col-md-3'>
            <div className='card py-3 mb-4 shadow-lg rounded-lg'>
              <div className='card-body'>
                <div className="small-box">
                  <div className="d-flex inner">
                    <div>
                      <h3>₦{eventAnalytics ? Number(eventAnalytics.total_sales).toLocaleString() : 0}</h3>
                      <p>Total Sales</p>
                    </div>
                    <span className="b-avatar badge-light-success rounded-circle ml-auto" style={{ width: '58px', height: '58px' }}>
                      <span className="b-avatar-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign">
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card py-3 mb-4 shadow-lg rounded-lg'>
              <div className='card-body'>
                <div className="small-box">
                  <div className="d-flex inner">
                    <div>
                      <h3>{eventAnalytics ? eventAnalytics.tickets_sold : 0}</h3>
                      <p>Tickets Sold</p>
                    </div>
                    <span className="b-avatar badge-light-primary rounded-circle ml-auto" style={{ width: '58px', height: '58px' }}>
                      <span className="b-avatar-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                          viewBox="0 0 24 24" fill="none" stroke="currentColor"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          className="feather feather-trending-up">
                          <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                          <polyline points="17 6 23 6 23 12"></polyline>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card py-3 mb-4 shadow-lg rounded-lg'>
              <div className='card-body'>
                <div className="small-box">
                  <div className="d-flex inner">
                    <div>
                      <h3>{eventAnalytics ? eventAnalytics.total_transactions : 0}</h3>
                      <p>Transactions</p>
                    </div>
                    <span className="b-avatar badge-light-danger rounded-circle ml-auto" style={{ width: '58px', height: '58px' }}>
                      <span className="b-avatar-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"
                          className="feather feather-box">
                          <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                          <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                          <line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card py-3 mb-4 shadow-lg rounded-lg'>
              <div className='card-body'>
                <div className="small-box">
                  <div className="d-flex inner">
                    <div>
                      <h3>{eventAnalytics ? eventAnalytics.views : 0}</h3>
                      <p>Views</p>
                    </div>
                    <span className="b-avatar badge-light-warning rounded-circle ml-auto" style={{ width: '58px', height: '58px' }}>
                      <span className="b-avatar-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye">
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z">
                          </path><circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-7'>
            <div className='card py-3 mb-4 shadow-lg rounded-lg'>
              <div className='card-body'>
                <h5 className='font-weight-bold mb-4'>Attendees</h5>
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <div className="form-group">
                      <input
                        type='text'
                        className='form-control'
                        name='search'
                        value={filters.search}
                        onChange={handleChange}
                        placeholder='Search by name, email or phone...'
                      />
                    </div>
                  </div>
                </div>
                {eventAttendees.length > 0 && (<div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Next of Kin</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventAttendees.map((user, i) => (
                        <tr key={user.id}>
                          <td>{i + 1}</td>
                          <td>{user.lastname} {user.firstname}</td>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td>{user.next_of_kin ? user.next_of_kin : '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>)}
                {eventAttendees.length == 0 && (
                  <div className='alert alert-info text-center'>No Record Found</div>
                )}
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='card py-3 mb-4 shadow-lg rounded-lg'>
              <div className='card-body'>
                <h5 className='font-weight-bold mb-4'>Ticket Sales Info</h5>
                {eventTickets.length > 0 && (<div className='table-responsive mt-1'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Ticket type</th>
                        <th>Status</th>
                        <th>Sold</th>
                        <th>Total sales (₦)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventTickets.map((ticket, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{ticket.name}</td>
                          <td>
                            <span className={`badge badge-pill text-capitalize 
                                                        ${ticket.status === 'sold_out' ? 'badge-light-warning' :
                                ticket.status === 'expired' ? 'badge-light-danger' :
                                  'badge-light-success'}`}
                            >
                              {ticket.status}
                            </span>
                          </td>
                          <td>{ticket.tickets_sold}</td>
                          <td>{ticket.total_sales ? Number(ticket.total_sales).toLocaleString() : '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>)}
                {eventTickets.length == 0 && (
                  <div className='alert alert-info text-center'>
                    No record found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
