// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { useAuth } from './hooks/useAuthContext';

// ----------------------------------------------------------------------

export default function App() {
  const { authIsReady }  = useAuth()

  return (
    <>
      { authIsReady &&
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
      }
    </>
  );
}
