import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuthContext';

export default function ChangePassword({ show, closeModal }) {
    const { user }  = useAuth()
    const token = user.access_token

    const [isLoading, setIsLoading] = useState(false)
    const [validationError, setValidationError] = useState([])
  //  state to hold form data
  const [formData, setFormData] = useState({
    oldpassword: '',
    password: '',
    confirm: '',
    id: user.data.id
  })

  //  method to reset form fields to empty
  const resetForm  = () => {
    setFormData({
        oldpassword: '',
        password: '',
        confirm: '',
        id: user.data.id
    })
  }


  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    const {name, value}  = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  //  method to call then modal is closed
  const handleClose = () => {
    resetForm()
    closeModal()
  }


  //  method to submit form
  const handleSubmit  = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    await axios
        .post(`${appURL}/api/users/change_password`, formData, {
            headers: {
                "Accept": "application/json",
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
            successNotify(res.data.message)
            closeModal()
        })
        .catch(error => {
            if(error.response.status == 420) {
              setValidationError(error.response.data.errors)
              errorNotify('An error occured!')
            } else {
              console.log(error.response.data.message)
              errorNotify('An error occured. Please, try again or reload page.')
            }
        })
        .finally(() => {
          setIsLoading(false)
        });
  }

  //  show  success or error notification on data submission
//   useEffect(() => {
//     if(res) {
//       successNotify(res)
//       resetForm()
//       closeModal()
//       refreshPage()
//     }
//     if(apiError) {
//       errorNotify(apiError)
//     }
//   }, [res, apiError])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
          <Modal.Header closeButton>
            <Modal.Title>Change Admin Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Label>Old Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter Old Password" 
                name='oldpassword' 
                value={formData.oldpassword}
                onChange={handleChange}
                required
              />
              {validationError.oldpassword && <small className='text-danger my-1'>{validationError.oldpassword[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicpwd">
              <Form.Label>New Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter New Password" 
                name='password' 
                value={formData.password}
                onChange={handleChange}
                required
              />
              {validationError.password && <small className='text-danger my-1'>{validationError.password[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formpwd">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Confirm New Password" 
                name='confirm' 
                value={formData.confirm}
                onChange={handleChange}
                required
              />
              {/* {validationError.password && <small className='text-danger my-1'>{validationError.password[0]}</small>} */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}