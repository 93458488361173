import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import axios from 'axios';
import { Card, Container, Typography } from '@mui/material';
import { useAuth } from 'src/hooks/useAuthContext';

export default function AddLocationModal({ show, closeModal, refreshPage, type }) {
    const { user } = useAuth()
    const token = user.access_token

    //    banner image preview
    const [bannerImgUrl, setBannerImgUrl] = useState('')
    //    cover image preview
    const [coverImgUrl, setCoverImgUrl] = useState('')
    //    image error
    const [imageError, setImageError] = useState('')
    //    countries state
    const [countries, setCountries] = useState([])

    const [validationError, setValidationError] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    //  state to hold form data
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        tags: '',
        country: '',
        location: '',
        state: '',
        city: '',
        status: ''
    })

    //  method to reset form fields to empty
    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            category: '',
            tags: '',
            country: '',
            location: '',
            state: '',
            city: '',
            status: '',
        })
        setBannerImgUrl('')
        setCoverImgUrl('')
    }

    //  call custom fetch hook to post data
    // const { apiData, res, apiError, isLoading, validationError, postData } = useFetch('POST', `${appURL}/api/events/admin/setup/age_range/create`)

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }


    //  method to submit form
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        formData.category = type
        axios
            .post(`${appURL}/api/location/admin/create`, formData, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": 'multipart/form-data',
                    "Authorization": `Bearer ${token}`
                },
            })
            .then((res) => {
                successNotify(res.data.message)
                resetForm()
                closeModal()
                refreshPage()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setValidationError(error.response.data.errors)
                } else if (error.response.status === 425) {
                    errorNotify(error.response.data.message)
                } else if (error.response.status === 420) {
                    errorNotify('Error with image upload')
                    setImageError(error.response.data.errors)
                }
                else {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    //  method to submit form
    // const handleSubmit  = async(e) => {
    //     e.preventDefault()
    //     setIsLoading(true)
    //     setValidationError({})
    //     const formObj = {
    //         ...formData,
    //         // description: QuillValue,
    //         productImage: fileArray,
    //         productSize: sizes,
    //         productColor: colors
    //     }

    //     await axios
    //         .post(`${appURL}/admin/products`, formObj, {
    //             headers: {
    //                 "Accept": "application/json",
    //                 "Content-Type": 'multipart/form-data',
    //                 "Authorization" : `Bearer ${token}`
    //             }
    //         })
    //         .then((res) => {
    //             successNotify(res.data.message)
    //             resetForm()
    //             closeModal()
    //             refreshPage()
    //         })
    //         .catch(error => {
    //             if(error.response.status === 420) {
    //                 setValidationError(error.response.data)
    //                 errorNotify('An error occured!')
    //             } else if (error.response.status === 405) {
    //                 setValidationError(error.response.data)
    //                 errorNotify('An error occured!')
    //             } else {
    //             console.log(error.response.data.message)
    //                 errorNotify('An error occured. Please, try again or reload page.')
    //             }
    //         })
    //         .finally(() => {
    //             setIsLoading(false)
    //         });
    // }

    //    method for image select
    const changeHandler = (event) => {
        const { name, files } = event.target
        setFormData(prevFormData => (
            { ...prevFormData, [name]: files[0] }
        ))

        //  preview image
        switch (name) {
            case 'bannerImg':
                setBannerImgUrl(URL.createObjectURL(files[0]))
                return
            // case 'mobileImg' :
            //     setMobileImgUrl(URL.createObjectURL(files[0]))
            //     return
            case 'coverImg':
                setCoverImgUrl(URL.createObjectURL(files[0]))
                return
            default:
                break
        }

    };

    //    function to fetch countries
    const fetchCountries = () => {
        axios
            .get("https://restcountries.com/v3.1/region/africa")
            .then((res) => {
                setCountries(res.data)
            })
            .finally(() => {
            });
    }

    //  show  success or error notification on data submission
    // useEffect(() => {
    //     if (res) {
    //         successNotify(res)
    //         resetForm()
    //         closeModal()
    //         refreshPage()
    //     }
    //     if (apiError) {
    //         errorNotify(apiError)
    //     }
    // }, [res, apiError])

    //    invoke fetch countries function
    useEffect(() => {
        fetchCountries()
    }, [])


    return (
        <>
            <Modal show={show} onHide={handleClose}  size='lg'>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Add New <span className='text-capitalize'>{type}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name='description'
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                            {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
                        </Form.Group>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='location' style={{ display: 'block' }}>
                                        <span>Location</span>
                                        <input className='form-control mt-2'
                                            type="text" name='location'
                                            value={formData.location}
                                            onChange={handleChange}
                                            placeholder='Location'
                                            required
                                        />
                                    </label>
                                    {validationError.locationName && <small className='text-danger my-1'>{validationError.locationName[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='country' style={{ display: 'block' }}>
                                        <span>Country</span>
                                        <select
                                            className='form-control mt-2'
                                            id='country'
                                            value={formData.country}
                                            onChange={handleChange}
                                            name='country'
                                            required
                                        >
                                            <option value=''>--Select Country--</option>
                                            {countries && (
                                                countries.map(country => (
                                                    <option key={country.name.common} value={country.name.common}>{country.name.common}</option>
                                                ))
                                            )}
                                        </select>
                                    </label>
                                    {validationError.country && <small className='text-danger my-1'>{validationError.country[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='state' style={{ display: 'block' }}>
                                        <span>State</span>
                                        <input className='form-control mt-2'
                                            type="text" name='state'
                                            value={formData.state}
                                            onChange={handleChange}
                                            placeholder='State'
                                            required
                                        />
                                    </label>
                                    {validationError.state && <small className='text-danger my-1'>{validationError.state[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='city' style={{ display: 'block' }}>
                                        <span>City</span>
                                        <input className='form-control mt-2'
                                            type="text" name='city'
                                            value={formData.city}
                                            onChange={handleChange}
                                            placeholder='City'
                                            required
                                        />
                                    </label>
                                    {validationError.city && <small className='text-danger my-1'>{validationError.city[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='tags' style={{ display: 'block' }}>
                                        <span>Add tags (separated by commas)</span>
                                        <input className='form-control mt-2' type="text" name='tags' value={formData.tags} onChange={handleChange} />
                                    </label>
                                    {validationError.tags && <small className='text-danger my-1'>{validationError.tags[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='status' style={{ display: 'block' }}>
                                        <span>Status</span>
                                        <select
                                            className='form-control mt-2'
                                            id='status'
                                            value={formData.status}
                                            onChange={handleChange}
                                            name='status'
                                        >
                                            <option value=''>--Select Status--</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="declined">Declined</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Card className='mb-1'>
                            <Container className='py-md-2 px-md-2'>
                                <Typography variant="h6" className='font-weight-normal pb-4'>
                                    <span className='text-capitalize'>{type}</span> Images
                                </Typography>
                                <div className='row'>
                                    <div className='col-md-6  mb-3'>
                                        <div className='event-image-wrapper text-center'>
                                            <Typography variant="h6" className='font-weight-normal'>
                                                Add <span className='text-capitalize'>{type}</span> Banner Image
                                            </Typography>
                                            <div className='input-wrapper'>
                                                <input
                                                    type='file'
                                                    className='form-control'
                                                    accept=".jpg, .jpeg, .png"
                                                    name='bannerImg'
                                                    onChange={changeHandler}
                                                    required
                                                />
                                            </div>
                                            <div className='image-display-wrapper d-flex mt-4' style={{ justifyContent: 'center' }}>
                                                {bannerImgUrl && (<img src={bannerImgUrl} alt='banner'
                                                    className='img-fluid' width={500}
                                                />)}
                                            </div>
                                            {imageError.banner && <small className='text-danger my-1'>{imageError.banner[0]}</small>}
                                            <Typography>
                                                (Please avoid including text on image)
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <div className='event-image-wrapper text-center'>
                                            <Typography variant="h6" className='font-weight-normal'>
                                                Add <span className='text-capitalize'>{type}</span> Cover Image
                                            </Typography>
                                            <div className='input-wrapper'>
                                                <input
                                                    type='file'
                                                    className='form-control'
                                                    accept=".jpg, .jpeg, .png"
                                                    name='coverImg'
                                                    onChange={changeHandler}
                                                    required
                                                />
                                            </div>
                                            <div className='image-display-wrapper d-flex mt-3' style={{ justifyContent: 'center' }}>
                                                {coverImgUrl && (<img src={coverImgUrl} alt='cover'
                                                    className='img-fluid' width={150}
                                                />)}
                                            </div>
                                            {imageError.cover && <small className='text-danger my-1'>{imageError.cover[0]}</small>}
                                            <Typography>
                                                (Please avoid including text on image)
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}