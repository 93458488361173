import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { useAuth } from './hooks/useAuthContext';
import EventCategoryPage from './pages/EventCategoryPage';
import EventAgeRangePage from './pages/EventAgeRangePage';
import EventsPage from './pages/EventsPage';
import TicketTransactionsPage from './pages/TicketTransactionsPage';
import EventAnalyticsPage from './pages/EventAnalyticsPage';
import ProfilePage from './pages/ProfilePage';
import UtilitiesPage from './pages/UtilitiesPage';
import WithdrawalsPage from './pages/WithdrawalsPage';
import LocationsPage from './pages/ManageLocation/Index';
import LocationTIcketsPage from './pages/ManageLocation/ManageTickets';
import MoviesPage from './pages/ManageLocation/MoviesPage';
import MovieTicketsPage from './pages/ManageLocation/MovieTickets';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuth()
  
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'admin', element: <AdminPage /> },
        { path: 'events', element: <EventsPage /> },
        { path: 'events/:id/analytics', element: <EventAnalyticsPage /> },
        { path: 'event/categories', element: <EventCategoryPage /> },
        { path: 'event/age_range', element: <EventAgeRangePage /> },
        { path: 'transactions', element: <TicketTransactionsPage /> },
        { path: 'withdrawals', element: <WithdrawalsPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'utilities', element: <UtilitiesPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'places', element: <LocationsPage /> },
        { path: 'places/:id/:type', element: <LocationTIcketsPage /> },
        { path: 'places/cinema/movies/:id', element: <MoviesPage /> },
        { path: 'places/cinema/movies/ticket/:id', element: <MovieTicketsPage /> }
      ],
    },
    {
      path: 'login',
      element: user ?  <Navigate to="/dashboard/app" /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
