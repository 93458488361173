import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import { errorNotify, successNotify } from './toatification';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import useFetch from '../../hooks/useFetch';
import axios from 'axios';

export default function EditLocation({ showEdit, closeModal, currentLocation, refreshPage }) {

    //  state to hold form data
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        tags: '',
        country: '',
        location: '',
        state: '',
        city: '',
        status: '',
        id: ''
    })

    const [countries, setCountries] = useState([])

    //  call custom fetch hook to post data
    const { apiData, res, apiError, isLoading, validationError, postData } = useFetch('POST', `${appURL}/api/location/admin/edit`)

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        closeModal()
    }

    //  method to submit form
    const handleSubmit = async(e) => {
        e.preventDefault()
        await postData(formData)
    }

    //    function to fetch countries
    const fetchCountries = () => {
        axios
            .get("https://restcountries.com/v3.1/region/africa")
            .then((res) => {
                setCountries(res.data)
            })
            .finally(() => {
            });
    }


    //    set form data on mount of edit modal
    useEffect(() => {
        setFormData({
            name: currentLocation ? currentLocation.name : '',
            description: currentLocation ? currentLocation.description : '',
            category: currentLocation ? currentLocation.category : '',
            tags: currentLocation ? currentLocation.tags : '',
            country: currentLocation ? currentLocation.country : '',
            location: currentLocation ? currentLocation.location : '',
            state: currentLocation ? currentLocation.state : '',
            city: currentLocation ? currentLocation.city : '',
            status: currentLocation ? currentLocation.status : '',
            id: currentLocation ? currentLocation.id : ''
        })
        fetchCountries()
    }, [currentLocation])

    useEffect(() => {
        if (res) {
            successNotify(res)
            closeModal()
            refreshPage()
        }
        if (apiError) {
            errorNotify(apiError)
        }
    }, [res, apiError])


    return (
        <>
            <Modal show={showEdit} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Updating..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Edit <b className='text-info'>{currentLocation ? currentLocation.name : 'Event Age range'}</b> details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name='description'
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                            {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
                        </Form.Group>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='location' style={{ display: 'block' }}>
                                        <span>Location</span>
                                        <input className='form-control mt-2'
                                            type="text" name='location'
                                            value={formData.location}
                                            onChange={handleChange}
                                            placeholder='Location'
                                            required
                                        />
                                    </label>
                                    {validationError.locationName && <small className='text-danger my-1'>{validationError.locationName[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='country' style={{ display: 'block' }}>
                                        <span>Country</span>
                                        <select
                                            className='form-control mt-2'
                                            id='country'
                                            value={formData.country}
                                            onChange={handleChange}
                                            name='country'
                                            required
                                        >
                                            <option value=''>--Select Country--</option>
                                            {countries && (
                                                countries.map(country => (
                                                    <option key={country.name.common} value={country.name.common}>{country.name.common}</option>
                                                ))
                                            )}
                                        </select>
                                    </label>
                                    {validationError.country && <small className='text-danger my-1'>{validationError.country[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='state' style={{ display: 'block' }}>
                                        <span>State</span>
                                        <input className='form-control mt-2'
                                            type="text" name='state'
                                            value={formData.state}
                                            onChange={handleChange}
                                            placeholder='State'
                                            required
                                        />
                                    </label>
                                    {validationError.state && <small className='text-danger my-1'>{validationError.state[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='city' style={{ display: 'block' }}>
                                        <span>City</span>
                                        <input className='form-control mt-2'
                                            type="text" name='city'
                                            value={formData.city}
                                            onChange={handleChange}
                                            placeholder='City'
                                            required
                                        />
                                    </label>
                                    {validationError.city && <small className='text-danger my-1'>{validationError.city[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='tags' style={{ display: 'block' }}>
                                        <span>Tags (separated by commas)</span>
                                        <input className='form-control mt-2' type="text" name='tags' value={formData.tags} onChange={handleChange} />
                                    </label>
                                    {validationError.tags && <small className='text-danger my-1'>{validationError.tags[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='status' style={{ display: 'block' }}>
                                        <span>Status</span>
                                        <select
                                            className='form-control mt-2'
                                            id='status'
                                            value={formData.status}
                                            onChange={handleChange}
                                            name='status'
                                        >
                                            <option value=''>--Select Status--</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="declined">Declined</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}