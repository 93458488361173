import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AddAdsModal from "src/components/modal/AddAdsBanner";
import AddBannerModal from "src/components/modal/AddBannerModal";
import { useAuth } from "src/hooks/useAuthContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { appURL } from "src/env";
import { errorNotify, successNotify } from '../components/modal/toatification';
import { SpinnerComponent } from 'react-element-spinner';
import Swal from 'sweetalert2';

export default function UtilitiesPage() {
    const { user }  = useAuth()
    const token = user.access_token

    // create banner modal show state
    const [showBanner, setShowBanner] = useState(false);

    // create ads modal show state
    const [showAds, setShowAds] = useState(false);

    //  state to refresh component after formsubmission
    const [refreshKey, setRefreshKey]  = useState(0)

    const [banners, setBanners] = useState([])
    const [ads, setAds] = useState([])

    const [isBannerLoading, setIsBannerLoading] = useState(false)
    const [isAdsLoading, setIsAdsLoading] =  useState(false)

    //  refresh User component
    const handleRefresh = () => {
        setRefreshKey(prevRefreshKey => prevRefreshKey +  1)
    }

    //  states for Banner modal
        // create modal
        const handleShowBanner = () => setShowBanner(true);

        //  close modal
        const handleCloseBanner = () => {
            setShowBanner(false)
        }
    //  end of Banner modal states

    //  states for Ads modal
        // create modal
        const handleShowAds = () => setShowAds(true);

        //  close modal
        const handleCloseAds = () => {
            setShowAds(false)
        }
    //  end of Ads modal states

    const fetchBanner = async() => {
        setIsBannerLoading(true)
        await axios
            .get(`${appURL}/api/utils/admin/banner/fetch`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
                setBanners(res.data.data)
            })
            .catch(error => {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
            })
            .finally(() => {
                setIsBannerLoading(false)
            });
    }

    const fetchAds = async() => {
        setIsAdsLoading(true)
        await axios
            .get(`${appURL}/api/utils/admin/ads/fetch`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
                setAds(res.data.data)
            })
            .catch(error => {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
            })
            .finally(() => {
                setIsAdsLoading(false)
            });
    }

    //  method to delete  Banner
    const deleteBanner = async (id) => {
    const isConfirm = await Swal.fire({
      title: 'Delete Page Banner',
      text: "Are you sure?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      return result.isConfirmed
    });

    if(!isConfirm){
      return;
    }
    setIsBannerLoading(true)
    await axios
        .delete(`${appURL}/api/utils/admin/banner/${id}`, {
            headers: {
                "Accept": "application/json",
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
            successNotify('Banner deleted succesfully')
            handleRefresh()
        })
        .catch(error => {
                console.log(error.response.data.message)
                errorNotify('An error occured. Please, try again or reload page.')
        })
        .finally(() => {
            setIsBannerLoading(false)
        });
    }

    //  method to delete  Ad
    const deleteAd = async (id) => {
        const isConfirm = await Swal.fire({
          title: 'Delete Page Advert',
          text: "Are you sure?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Continue!'
        }).then((result) => {
          return result.isConfirmed
        });
    
        if(!isConfirm){
          return;
        }
        setIsAdsLoading(true)
        await axios
            .delete(`${appURL}/api/utils/admin/ads/${id}`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
                successNotify('Advert deleted succesfully')
                handleRefresh()
            })
            .catch(error => {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
            })
            .finally(() => {
                setIsAdsLoading(false)
            });
        }

    useEffect(() => {
        fetchBanner()
        fetchAds()
    }, [refreshKey])


    return (
        <>
            <Helmet>
                <title>Utilities | Wagwan</title>
            </Helmet>

            <Container>
                <Typography variant="h4" gutterBottom>
                    Utilities
                </Typography>
                <div className="row pt-md-4 pt-3">
                    <div className="col-md-6 mb-3">
                        <div className="card border-0 shadow-sm">
                            <SpinnerComponent loading={isBannerLoading} message="Loading..." position="global" />
                            <div className="card-header d-flex">
                                <span>Banner</span>
                                <button onClick={handleShowBanner}
                                    className="btn btn-primary btn-sm ml-auto"
                                >
                                    <i className="mdi mdi-add mr-2"></i>
                                    New Banner
                                </button>
                            </div>
                            <div className="card-body">
                                {banners.length >  0 && (<div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {banners.map((banner, i) => (
                                                <tr key={banner.id}>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        <img 
                                                            src={banner.banner_img}  
                                                            alt={banner.banner_img} 
                                                            width="300"
                                                            className="img-fluid"
                                                        />
                                                    </td>
                                                    <td>{banner.description}</td>
                                                    <td>
                                                        <i 
                                                            className="mdi mdi-trash-can-outline"
                                                            style={{fontSize: '20px', color: 'red', cursor: 'pointer'}}
                                                            onClick={() => deleteBanner(banner.id)}
                                                            title='Delete'
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>)}
                                {banners.length == 0 && (
                                    <div className="alert alert-info text-center">
                                        No Record Found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card border-0 shadow-sm">
                            <SpinnerComponent loading={isAdsLoading} message="Loading..." position="global" />
                            <div className="card-header d-flex">
                                <span>Ads</span>
                                <button className="btn btn-primary btn-sm ml-auto" onClick={handleShowAds}>
                                    <i className="mdi mdi-add mr-2"></i>
                                    New Ad
                                </button>
                            </div>
                            <div className="card-body">
                                {ads.length > 0 && (<div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Image</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {ads.map((ad, i) => (
                                                <tr key={ad.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{ad.title}</td>
                                                    <td>
                                                        <img 
                                                            src={ad.ad_img}  
                                                            alt={ad.ad_img} 
                                                            width="300"
                                                            className="img-fluid"
                                                        />
                                                    </td>
                                                    <td>
                                                        <i 
                                                            className="mdi mdi-trash-can-outline"
                                                            style={{fontSize: '20px', color: 'red', cursor: 'pointer'}}
                                                            onClick={() => deleteAd(ad.id)}
                                                            title='delete'
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </table>
                                </div>)}
                                {ads.length == 0 && (
                                    <div className="alert alert-info text-center">
                                        No Record Found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <AddBannerModal show={showBanner} closeModal={handleCloseBanner} refreshPage={handleRefresh} />
            <AddAdsModal show={showAds} closeModal={handleCloseAds} refreshPage={handleRefresh} />

            <ToastContainer />
        </>
    )
}