import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import { errorNotify, successNotify } from './toatification';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import useFetch from '../../hooks/useFetch';
import axios from 'axios';

export default function EditMovieModal({ showEdit, closeModal, currentMovie, refreshPage }) {

    //  state to hold form data
    const [formData, setFormData] = useState({
        type: '', ticketName: '', maxQuantity: '', price: '', serviceFee: '', ticketDesc: '',
        saleStart: '', saleStop: '', saleMode: 'public', saleChannel: 'everyone', minOrder: '',
        maxOrder: '', id: '', status: '', category: ''
    })

    //  call custom fetch hook to post data
    const { apiData, res, apiError, isLoading, validationError, postData } = useFetch('POST', `${appURL}/api/location/admin/movies/edit`)

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        closeModal()
    }

    //  method to submit form
    const handleSubmit = async (e) => {
        e.preventDefault()
        await postData(formData)
    }

    //    set form data on mount of edit modal
    useEffect(() => {
        setFormData({
            title: currentMovie ? currentMovie.title : '',
            description: currentMovie ? currentMovie.description : '',
            year: currentMovie ? currentMovie.movie_year : '',
            genre: currentMovie ? currentMovie.genre : '',
            duration: currentMovie ? currentMovie.duration : '',
            status: currentMovie ? currentMovie.status : '',
            id: currentMovie ? currentMovie.id : ''
        })
    }, [currentMovie])

    useEffect(() => {
        if (res) {
            successNotify(res)
            closeModal()
            refreshPage()
        }
        if (apiError) {
            errorNotify(apiError)
        }
    }, [res, apiError])


    return (
        <>
            <Modal show={showEdit} onHide={handleClose} size='lg'>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Updating..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Edit <b className='text-info'>{currentMovie ? currentMovie.name : 'Ticket'}</b> Ticket details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Title"
                                name='title'
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                            {validationError.title && <small className='text-danger my-1'>{validationError.title[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name='description'
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                            {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
                        </Form.Group>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='year' style={{ display: 'block' }}>
                                        <span>Movie Year</span>
                                        <input className='form-control mt-2'
                                            type="text" name='year'
                                            value={formData.year}
                                            onChange={handleChange}
                                            placeholder='Enter Movie release year'
                                            required
                                        />
                                    </label>
                                    {validationError.year && <small className='text-danger my-1'>{validationError.year[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='duration' style={{ display: 'block' }}>
                                        <span>Movie Duration</span>
                                        <input className='form-control mt-2'
                                            type="text" name='duration'
                                            value={formData.duration}
                                            onChange={handleChange}
                                            placeholder='Enter movie duration (e.g. 2hrs 30min)'
                                            required
                                        />
                                    </label>
                                    {validationError.duration && <small className='text-danger my-1'>{validationError.duration[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='genre' style={{ display: 'block' }}>
                                        <span>Add Movie genre (separated by commas)</span>
                                        <input className='form-control mt-2' type="text" name='genre' value={formData.genre} onChange={handleChange} />
                                    </label>
                                    {validationError.genre && <small className='text-danger my-1'>{validationError.genre[0]}</small>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label htmlFor='status' style={{ display: 'block' }}>
                                        <span>Status</span>
                                        <select
                                            className='form-control mt-2'
                                            id='status'
                                            value={formData.status}
                                            onChange={handleChange}
                                            name='status'
                                        >
                                            <option value=''>--Select Status--</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="declined">Declined</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}