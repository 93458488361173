import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import { errorNotify, successNotify } from './toatification';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import useFetch from '../../hooks/useFetch';

export default function EditEventCategory({ showEdit, closeModal, currentEventCategory, refreshPage }) {

  //  state to hold form data
  const [formData, setFormData] = useState({
    name: '',
    status: '',
    id: '' 
  })

  //  call custom fetch hook to post data
  const {apiData, res, apiError, isLoading, validationError, postData} = useFetch('PUT', `${appURL}/api/events/admin/setup/category/edit`)

  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    const {name, value}  = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  //  method to call then modal is closed
  const handleClose = () => {
    closeModal()
  }

  //  method to submit form
  const handleSubmit  = (e) => {
    e.preventDefault()
    postData(formData)
  }

  //    set form data on mount of edit modal
  useEffect(() => {
    setFormData({
        name: currentEventCategory ? currentEventCategory.name : '',
        status: currentEventCategory ? currentEventCategory.status : '' ,
        id: currentEventCategory ? currentEventCategory.id : '' 
    })
  }, [currentEventCategory])

  useEffect(() => {
    if(res) {
      successNotify(res)
      closeModal()
      refreshPage()
    }
    if(apiError) {
      errorNotify(apiError)
    }
  }, [res, apiError])


  return (
    <>
      <Modal show={showEdit} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <SpinnerComponent loading={isLoading} message="Updating..." position="centered" />
          <Modal.Header closeButton>
            <Modal.Title>Edit <b className='text-info'>{currentEventCategory ? currentEventCategory.name : 'Event Category'}</b> details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Label>name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter name" 
                name='name' 
                value={formData.name}
                onChange={handleChange}
                required
              />
              {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
            </Form.Group>
            <div className='mb-3'>
                <label htmlFor='status' style={{display: 'block'}}>
                    <span>Status</span>
                    <select 
                        className='form-control mt-2' 
                        id='status'
                        value={formData.status}
                        onChange={handleChange}
                        name='status'
                    >
                        <option value=''>--Select Status--</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}