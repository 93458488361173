import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import useFetch from '../../hooks/useFetch';

export default function EditUserModal({ showEdit, closeModal, currentUser, refreshPage }) {

  //  state to hold form data
  const [formData, setFormData] = useState({
    lastname: '',
    firstname: '',
    middlename: '',
    email: '',
    phone: '',
    userType: '',
    status: '',
    id: '' 
  })

  //  call custom fetch hook to post data
  const {apiData, res, apiError, isLoading, validationError, postData} = useFetch('PUT', `${appURL}/api/users/edit`)

  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    const {name, value}  = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  //  method to call then modal is closed
  const handleClose = () => {
    closeModal()
  }

  //  show toaster
  const errorNotify = (message) => toast.error(message, {
    autoClose: 3000,
    hideProgressBar: true,
    theme: "colored",
  });

  const successNotify = (message) => toast.success(message, {
    autoClose: 3000,
    hideProgressBar: true,
    theme: "colored",
  })

  //  method to submit form
  const handleSubmit  = (e) => {
    e.preventDefault()
    postData(formData)
  }

  //    set form data on mount of edit modal
  useEffect(() => {
    setFormData({
        lastname: currentUser ? currentUser.lastname : '',
        firstname: currentUser ? currentUser.firstname : '',
        middlename: currentUser && currentUser.middlename != null ? currentUser.middlename : '',
        email: currentUser ? currentUser.email : '',
        phone: currentUser ? currentUser.phone : '',
        userType: currentUser ? currentUser.user_type : '',
        status: currentUser ? currentUser.status : '' ,
        id: currentUser ? currentUser.id : '' 
    })
  }, [currentUser])

  useEffect(() => {
    if(res) {
      successNotify(res)
      closeModal()
      refreshPage()
    }
    if(apiError) {
      errorNotify(apiError)
    }
  }, [res, apiError])


  return (
    <>
      <Modal show={showEdit} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <SpinnerComponent loading={isLoading} message="Updating..." position="centered" />
          <Modal.Header closeButton>
            <Modal.Title>Edit <b className='text-info'>{currentUser ? currentUser.name : 'User'}</b> details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicLastname">
              <Form.Label>Lastname</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Lastname" 
                name='lastname' 
                value={formData.lastname}
                onChange={handleChange}
                required
              />
              {validationError.lastname && <small className='text-danger my-1'>{validationError.lastname[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicFirstname">
              <Form.Label>Firstname</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Firstname" 
                name='firstname' 
                value={formData.firstname}
                onChange={handleChange}
                required
              />
              {validationError.firstname && <small className='text-danger my-1'>{validationError.firstname[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicMiddlename">
              <Form.Label>Middlename</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Middlename" 
                name='middlename' 
                value={formData.middlename}
                onChange={handleChange}
              />
              {validationError.middlename && <small className='text-danger my-1'>{validationError.middlename[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                name='email' 
                value={formData.email}
                onChange={handleChange}
                required
              />
              {validationError.email && <small className='text-danger my-1'>{validationError.email[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Phone" 
                name='phone' 
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationError.phone && <small className='text-danger my-1'>{validationError.phone[0]}</small>}
            </Form.Group>
            <div className='mb-3'>
                <label htmlFor='userType' style={{display: 'block'}}>
                    <span>User Type</span>
                    <select 
                        className='form-control mt-2' 
                        id='userType'
                        value={formData.userType}
                        onChange={handleChange}
                        name='userType'
                    >
                        <option value=''>--Select user type--</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </select>
                </label>
            </div>
            <div className='mb-3'>
                <label htmlFor='status' style={{display: 'block'}}>
                    <span>Status</span>
                    <select 
                        className='form-control mt-2' 
                        id='status'
                        value={formData.status}
                        onChange={handleChange}
                        name='status'
                    >
                        <option value=''>--Select Status--</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}