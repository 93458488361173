import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import useFetch from '../../hooks/useFetch';

export default function AddBannerModal({ show, closeModal, refreshPage }) {

  //  state to hold form data
  const [formData, setFormData] = useState({
    bannerImg: '',
    description: ''
  })

  //    banner image preview
  const [bannerImgUrl, setBannerImgUrl] = useState('')

  //  method to reset form fields to empty
  const resetForm  = () => {
    setFormData({
        bannerImg: '',
        description: ''
    })
    setBannerImgUrl('')
  }

  //  call custom fetch hook to post data
  const {apiData, res, apiError, isLoading, validationError, postData} = useFetch('POST', `${appURL}/api/utils/admin/banner/create`)

  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    const {name, value}  = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

    //    method for image select
    const changeHandler = (event) => {
        const { name, files } = event.target
        setFormData(prevFormData => (
            { ...prevFormData, [name]: files[0] }
        ))
        setBannerImgUrl(URL.createObjectURL(files[0]))
    };

  //  method to call then modal is closed
  const handleClose = () => {
    resetForm()
    closeModal()
  }

  //  method to submit form
  const handleSubmit  = async(e) => {
    e.preventDefault()
    let form = new FormData()
    await form.append('description', formData.description)
    await form.append('bannerImg', formData.bannerImg)

    postData(form)
  }

  //  show  success or error notification on data submission
  useEffect(() => {
    if(res) {
      successNotify(res)
      resetForm()
      closeModal()
      refreshPage()
    }
    if(apiError) {
      errorNotify(apiError)
    }
  }, [res, apiError])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
          <Modal.Header closeButton>
            <Modal.Title>New Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {bannerImgUrl && (<div className='image-display-wrapper d-flex mb-4' style={{ justifyContent: 'center' }}>
                <img src={bannerImgUrl} alt='banner'
                    className='img-fluid' width={500}
                />
            </div>)}
            <div className='mb-3'>
                <label htmlFor='status' style={{display: 'block'}}>
                    <span>Banner Image</span>
                    <input 
                        type='file' 
                        className='form-control'
                        accept=".jpg, .jpeg, .png"
                        name='bannerImg'
                        onChange={changeHandler}
                        required
                    />
                </label>
                {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
            </div>
            <div className='mb-3'>
                <label htmlFor='status' style={{display: 'block'}}>
                    <span>Description</span>
                    <textarea 
                        rows='3' 
                        className='form-control'
                        name='description'
                        value={formData.description}
                        onChange={handleChange}
                        required
                    />
                </label>
            </div>
            {/* <div className='mb-3'>
                <label htmlFor='status' style={{display: 'block'}}>
                    <span>Status</span>
                    <select 
                        className='form-control mt-2' 
                        id='status'
                        value={formData.status}
                        onChange={handleChange}
                        name='status'
                    >
                        <option value=''>--Select Status--</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </label>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}