import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useFetch from '../../hooks/useFetch';
import { appURL } from '../../env';

export default function AddUserModal({ show, closeModal, refreshPage }) {

  //  state to hold form data
  const [formData, setFormData] = useState({
    lastname: '',
    firstname: '',
    middlename: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: ''
  })

  //  method to reset form fields to empty
  const resetForm  = () => {
    setFormData({
      lastname: '',
      firstname: '',
      middlename: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: ''
    })
  }

  //  call custom fetch hook to post data
  const {apiData, res, apiError, isLoading, validationError, postData} = useFetch('POST', `${appURL}/api/users/admin/create`)

  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    const {name, value}  = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  //  method to call then modal is closed
  const handleClose = () => {
    resetForm()
    closeModal()
  }

  //  show toaster
  const errorNotify = (message) => toast.error(message, {
    autoClose: 3000,
    hideProgressBar: true,
    theme: "colored",
  });

  const successNotify = (message) => toast.success(message, {
    autoClose: 3000,
    hideProgressBar: true,
    theme: "colored",
  })

  //  method to submit form
  const handleSubmit  = (e) => {
    e.preventDefault()
    postData(formData)
  }

  //  show  success or error notification on data submission
  useEffect(() => {
    if(res) {
      successNotify(res)
      resetForm()
      closeModal()
      refreshPage()
    }
    if(apiError) {
      errorNotify(apiError)
    }
  }, [res, apiError])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
          <Modal.Header closeButton>
            <Modal.Title>Add new Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicLastname">
              <Form.Label>Lastname</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Lastname" 
                name='lastname' 
                value={formData.lastname}
                onChange={handleChange}
                required
              />
              {validationError.lastname && <small className='text-danger my-1'>{validationError.lastname[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicFirstname">
              <Form.Label>Firstname</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Firstname" 
                name='firstname' 
                value={formData.firstname}
                onChange={handleChange}
                required
              />
              {validationError.firstname && <small className='text-danger my-1'>{validationError.firstname[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicMiddlename">
              <Form.Label>Middlename</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Middlename" 
                name='middlename' 
                value={formData.middlename}
                onChange={handleChange}
              />
              {validationError.middlename && <small className='text-danger my-1'>{validationError.middlename[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                name='email' 
                value={formData.email}
                onChange={handleChange}
                required
              />
              {validationError.email && <small className='text-danger my-1'>{validationError.email[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Phone" 
                name='phone' 
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationError.phone && <small className='text-danger my-1'>{validationError.phone[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter Password" 
                name='password' 
                value={formData.password}
                onChange={handleChange}
                required
              />
              {validationError.password && <small className='text-danger my-1'>{validationError.password[0]}</small>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Confirm Password" 
                name='password_confirmation' 
                value={formData.password_confirmation}
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}