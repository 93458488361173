import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import useFetch from '../../hooks/useFetch';
import { errorNotify, successNotify } from '../../components/modal/toatification';
import { appURL } from '../../env';
import { useAuth } from 'src/hooks/useAuthContext';
import axios from 'axios';
import AddLocationModal from 'src/components/modal/AddLocation';
import EditLocation from 'src/components/modal/EditLocation';
import { NavLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BeachPage() {
  const { user }  = useAuth()
  const token = user.access_token

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  // create modal show state
  const [show, setShow] = useState(false);

  //  edit modal show state
  const [showEdit, setShowEdit] = useState(false);

  //  state to hold current user when dropdown menu is clicked
  const [currentLocation, setcurrentLocation] = useState(null)

  //  state to refresh component after formsubmission
  const [refreshKey, setRefreshKey]  = useState(0)

  //  data from custom fetch hook
  const {apiData: USERLIST, isLoading} = useFetch('GET', `${appURL}/api/location/admin/beach`, refreshKey)
  
  
  //  set state to current user when action dropdown is clicked
  const setLocation = (data) => {
    setcurrentLocation(data)
  }

  //  close action dropdown menu
  const handleCloseMenu = () => {
    setOpen(null);
    setcurrentLocation(null);
  };

  //  refresh User component
  const handleRefresh = () => {
    setRefreshKey(prevRefreshKey => prevRefreshKey +  1)
  }

  //  method to delete  user
  const handleDelete = async () => {
    const isConfirm = await Swal.fire({
      title: `Delete ${currentLocation?.name}`,
      text: "Are you sure?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      return result.isConfirmed
    });

    if(!isConfirm){
      return;
    }

    await axios
      .post(`${appURL}/api/location/admin/delete`, currentLocation, {
          headers: {
              "Accept": "application/json",
              "Authorization" : `Bearer ${token}`
          }
      })
      .then((res) => {
          successNotify(`${currentLocation.name} deleted succesfully`)
          handleRefresh()
      })
      .catch(error => {
              console.log(error.response.data.message)
              errorNotify('An error occured. Please, try again or reload page.')
      })
      .finally(() => {
      });
  }

  //  states for modal
    // create modal
    const handleShow = () => setShow(true);

    //  close modal
    const handleClose = () => {
      setShow(false)
      setShowEdit(false)
    }
  //  end of modal states

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // useEffect(() => {
  //   if(res) {
  //     successNotify(res)
  //     // handleRefresh()
  //   }
  //   if(apiError) {
  //     errorNotify(apiError)
  //   }
  // }, [res, apiError])

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Locations | Wagwan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Beaches
          </Typography>
          <Button variant="contained" onClick={handleShow} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Beach
          </Button>
        </Stack>

        <SpinnerComponent loading={isLoading} message="Submitting..." position="global" />

        {USERLIST && <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 500 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, status, city, state, country, cover_image, banner_image, location, tags } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell>
                          {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={cover_image} />
                            <Typography variant="subtitle2">
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                            <Typography variant="subtitle2">
                                {sentenceCase(location)}
                            </Typography>
                            <Typography variant="subtitle2" noWrap>
                                {sentenceCase(state)} state
                            </Typography>
                            <Typography variant="subtitle2" noWrap>
                                {sentenceCase(city)}, {sentenceCase(country)}
                            </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Label color={(status === 'inactive' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => {
                            setOpen(e.currentTarget); setLocation(row)
                          }}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem  onClick={() => {
          setShowEdit(true); setOpen(null)
        }}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'info.main' }} >
            <NavLink to={`/dashboard/places/${currentLocation?.id}/beach`}>
                <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
                Tickets
            </NavLink>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => {
          setOpen(null); handleDelete()
        }} >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <AddLocationModal show={show} closeModal={handleClose} refreshPage={handleRefresh} type={'beach'}/>
      <EditLocation
        showEdit={showEdit} closeModal={handleClose} 
        currentLocation={currentLocation} refreshPage={handleRefresh}
      />
      
      <ToastContainer />
    </>
  );
}
