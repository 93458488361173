import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// mock
import POSTS from '../_mock/blog';
import { useAuth } from 'src/hooks/useAuthContext';
import './ProfilePage.css'
import ChangePassword from '../components/modal/ChangePasswordModal'
import axios from 'axios';
import { useState } from 'react';
import { errorNotify, successNotify } from '../components/modal/toatification';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ProfilePage() {
  const { user } = useAuth()
  const token = user.access_token

  // create modal show state
  const [show, setShow] = useState(false);

  //  states for modal
    // create modal
    const handleShow = () => setShow(true);

    //  close modal
    const handleClose = () => {
      setShow(false)
    }
  //  end of modal states

  return (
    <>
      <Helmet>
        <title> Profile | Wagwan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Stack>

        <div className="row mt-5">
            <div className="col-md-4">
                <div className="card shadow-sm">
                    <div className="card-header image-head">
                        <div className="d-flex justify-content-center mt-5">
                            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyudBxqf1sdD2e3L4nI3nqsMt1_tceOyuZ7A&usqp=CAU' alt=""
                                className="rounded-circle position-relative" width="90"
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="text-center" style={{marginTop: "37px", lineHeight: "1.8"}}>
                            <h5 style={{fontWeight: "480", color: "#333333"}}>
                              {user.data.lastname} {user.data.firstname}
                            </h5>
                            <p style={{fontWeight: "400", textTransform: "capitalize"}}>{user.data.user_type}</p>
                            <small>{user.data.email}</small>
                        </div>
                        <div className="text-center my-4">
                            <button className='btn btn-block btn-primary' onClick={handleShow}>
                                Change Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className="card" style={{borderRadius: "8px"}}>
                    <div className="card-header bg-white info-head" style={{borderBottom: "1px solid #9999991f"}}>
                        <h5 className="float-left mt-2" style={{fontWeight: "450"}}>Profile Details</h5>
                        {/* <span className="float-right">
                            <button className="btn btn-info btn-sm">
                               <i className="mdi mdi-account-edit"></i> Edit Info
                            </button>
                        </span> */}
                    </div>
                    <div className="card-body">
                        <div className="row mt-3">
                            <div className="col-md-12 mb-md-5">
                                <div className="info-wrapper">
                                    <label htmlFor="name" style={{fontWeight: "500", fontSize: "14px"}}>NAME</label>
                                    <p style={{fontSize: "14px", lineHeight: "1.8", color: "#888888"}}>
                                        {user.data.lastname} {user.data.firstname} {user.data.middlename}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 mb-md-5">
                                <div className="info-wrapper">
                                    <label htmlFor="lname" style={{fontWeight: "500", fontSize: "14px"}}>EMAIL</label>
                                    <p style={{fontSize: "14px", lineHeight: "1.8", color: "#888888"}}>
                                        {user.data.email}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 mb-md-5">
                                <div className="info-wrapper">
                                    <label htmlFor="lname" style={{fontWeight: "500", fontSize: "14px"}}>PHONE NUMBER</label>
                                    <p style={{fontSize: "14px", lineHeight: "1.8", color: "#888888"}}>
                                        {user.data.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Container>
      <ChangePassword show={show} closeModal={handleClose} />
    </>
  );
}
