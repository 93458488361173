import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
import BeachPage from './BeachPage';
import ResortPage from './ResortPage';
import CinemaPage from './CinemaPage';


export default function LocationsPage() {

  return (
    <>
      <Helmet>
        <title> Places | Wagwan </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Places
          </Typography>
        </Stack>
        <div className='row'>
            <div className='col-md-6 px-0 mb-md-5 mb-3'>
              <BeachPage />
            </div>
            <div className='col-md-6 px-0 mb-md-5 mb-3'>
              <ResortPage />
            </div>
            <div className='col-md-6 px-0 mb-3'>
              <CinemaPage />
            </div>
        </div>
      </Container>
    </>
  );
}
