import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import useFetch from '../../hooks/useFetch';

export default function AddEventAgeRange({ show, closeModal, refreshPage }) {

  //  state to hold form data
  const [formData, setFormData] = useState({
    name: '',
    status: ''
  })

  //  method to reset form fields to empty
  const resetForm  = () => {
    setFormData({
        name: '',
        status: ''
    })
  }

  //  call custom fetch hook to post data
  const {apiData, res, apiError, isLoading, validationError, postData} = useFetch('POST', `${appURL}/api/events/admin/setup/age_range/create`)

  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    const {name, value}  = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  //  method to call then modal is closed
  const handleClose = () => {
    resetForm()
    closeModal()
  }


  //  method to submit form
  const handleSubmit  = (e) => {
    e.preventDefault()
    postData(formData)
  }

  //  show  success or error notification on data submission
  useEffect(() => {
    if(res) {
      successNotify(res)
      resetForm()
      closeModal()
      refreshPage()
    }
    if(apiError) {
      errorNotify(apiError)
    }
  }, [res, apiError])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
          <Modal.Header closeButton>
            <Modal.Title>Add Event Age range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Label>name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter name" 
                name='name' 
                value={formData.name}
                onChange={handleChange}
                required
              />
              {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
            </Form.Group>
            <div className='mb-3'>
                <label htmlFor='status' style={{display: 'block'}}>
                    <span>Status</span>
                    <select 
                        className='form-control mt-2' 
                        id='status'
                        value={formData.status}
                        onChange={handleChange}
                        name='status'
                    >
                        <option value=''>--Select Status--</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}