import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appURL } from '../../env';
import { errorNotify, successNotify } from './toatification';
import axios from 'axios';
import { nanoid } from 'nanoid'
import useFetch from '../../hooks/useFetch';
import { Card, Container, Typography } from '@mui/material';
import './AddEventModal.css'
import { useAuth } from '../../hooks/useAuthContext';

export default function SetPortalFee({ showServiceFee, currentEvent, closeModal, refreshPage }) {
    const { user } = useAuth()
    const token = user.access_token

    //  state to hold form data
    const [formData, setFormData] = useState(currentEvent.event_ticket)
    console.log(formData);

    //    countries state
    const [countries, setCountries] = useState([])

    const [validationError, setValidationError] = useState({})
    const [isLoading, setIsLoading] = useState(false);


    //   console.log(bannerImgError, mobileImgError, coverImgError);

    //  method to reset form fields to empty
    const resetForm = () => {
        setFormData(currentEvent.event_ticket)
    }

    //  listen for changes on ticket settings form inputs and store in state
    const handleTicketChange = (index, e) => {
        const { name, value } = e.target

        setFormData(prevFormData => prevFormData.map((t, i) => (
            i === index ? { ...t, [name]: value } : t
        )))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }

    //  method to submit form
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        axios
            .post(`${appURL}/api/events/admin/set_service_charge`, formData, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then((res) => {
                successNotify(res.data.message)
                resetForm()
                closeModal()
                refreshPage()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setValidationError(error.response.data.errors)
                } else if (error.response.status === 425) {
                    errorNotify(error.response.data.message)
                }
                else {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        setFormData(currentEvent.event_ticket)
    }, [currentEvent])

    return (
        <>
            <Modal show={showServiceFee} onHide={handleClose} size="lg" keyboard={false}>
                <Form onSubmit={handleSubmit}>
                    <SpinnerComponent loading={isLoading} message="Submitting..." position="centered" />
                    <Modal.Header closeButton>
                        <Modal.Title>Add Service Fee for <b className='text-info'>{currentEvent.name}</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-light'>
                        <Card className='mb-5'>
                            <Container className='py-md-3 px-md-3'>
                                <Typography variant="h4" className='font-weight-normal pb-4'>
                                    Tickets
                                </Typography>
                                <div className='table-responsive'>
                                    <table className='table table-sm table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Ticket Name</th>
                                                <th>Ticket Price (₦)</th>
                                                <th width='25%'>Service Fee (₦)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentEvent && currentEvent.event_ticket.map((ticket, i) => (
                                                <tr>
                                                    <td>{ticket.name}</td>
                                                    <td>{Number(ticket.price).toLocaleString()}</td>
                                                    <td>
                                                        <input 
                                                            type='number'
                                                            className='form-control'
                                                            name='service_fee'
                                                            onChange={(e) => handleTicketChange(i, e)}
                                                            placeholder='Enter an amount'
                                                            value={formData[i].service_fee}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Container>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' size='lg' className='px-5'>
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </>
    );
}